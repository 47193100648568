<template>
  <Head>
    <title v-if="content && content.title !== null">{{ content.title }}</title>
    <title v-else>
      Best Online Coaching for Neet, IIT-JEE, CBSE,CA and Junior IAS Initiative
      - VB Live
    </title>
    <meta
      v-if="content && content.meta_description !== null"
      name="description"
      :content="content.meta_description"
    />
    <meta
      v-else
      name="description"
      content="VB Live is the Best Online Coaching Platform for NEET, IIT-JEE, CBSE, Junior IAS Initiative and CA Foundation. We provides all Courses from Class 6 to Class 12 with Experienced Faculty."
    />
    <meta
      v-if="content && content.meta_keywords !== null"
      name="keywords"
      :content="content.meta_keywords"
    />
    <meta
      v-else
      name="keywords"
      content="online training, courses online, online training courses, online tuition, online tuition classes for class 10, best online tuition for class 10, Best online tuition, best courses online, best online training, online tuition classes for class 12, best online tuition for class 12, best online coaching, ias online classes"
    />
  </Head>
  <div class="listing">
    <div class="main-list-wrapper">
      <div class="listing-banner">
        <div class="container">
          <div class="row _content align-items-center">
            <div class="col-md-4">
              <h2 v-if="content">{{ content.title }}</h2>
              <div v-else class="loading">Loading...</div>
              <p v-if="content" v-html="content.content"></p>
            </div>
            <div class="col-md-8">
              <img
                src="../assets/explr-banner-img.png"
                class="d-block"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
      <div class="filter-header">
        <div ref="courses_head" class="container">
          <ul class="list-unstyled filter-list">
            <li>
              <select
                class="form-control select-button std"
                v-model="boardId"
                @change="resetProgramsFetch"
              >
                <option value="">Select Board</option>
                <option
                  v-for="(board, boardIndex) in boards"
                  :key="boardIndex"
                  :value="board.id"
                >
                  {{ board.board_title }}
                </option>
              </select>
            </li>
            <li>
              <select
                class="form-control select-button std"
                v-model="standardId"
                @change="resetProgramsFetch"
              >
                <option value="">Select Standard</option>
                <option
                  v-for="(standard, standardIndex) in standards"
                  :key="standardIndex"
                  :value="standard.id"
                >
                  {{ standard.standard_title }}
                </option>
              </select>
            </li>
            <li>
              <input
                type="text"
                class="form-control select-button search-filter"
                v-model="search"
                @keyup="resetProgramsFetch"
                placeholder="Search by name"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="container">
        <h3>Our courses</h3>
        <div v-if="programLoader" class="loading">Loading...</div>
        <div class="row course-row" v-else-if="programs">
          <CourseCard
            v-for="program in programs"
            :key="program.id"
            :program="program"
            :toggleImage="false"
            :toggleBanner="true"
            :togglePrice="true"
            :colValue="colval"
            :toggleFilteredWeeks="true"
            routeLink="CourseDetails"
          />
          <div class="paging-wrapper py-3">
            <v-pagination
              v-model="coursePage"
              :pages="totalCoursePages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="coursePaginateUpdateHandler"
              v-if="totalCourses > 0"
            />
          </div>
          <EmptyList v-if="totalCourses == 0" messageText="No courses found!" />
        </div>
      </div>
    </div>
    <div class="review-wrapper">
      <div class="experience-wrapper container">
        <Testimonial
          v-if="sortedTestimonials.length > 0"
          :sortedTestimonials="sortedTestimonials"
        />
      </div>
    </div>
    <CareerCounselling />
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard.vue";
import EmptyList from "@/components/EmptyList.vue";
import Testimonial from "@/components/Testimonial.vue";
import CareerCounselling from "@/components/CareerCounselling.vue";
import CourseService from "@/services/CourseService";
import VPagination from "@hennge/vue3-pagination";
import CommonService from "@/services/CommonService";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { Head } from "@vueuse/head";

export default {
  name: "ExploreCourses",
  components: {
    Head,
    CourseCard,
    VPagination,
    EmptyList,
    Testimonial,
    CareerCounselling,
  },
  data() {
    return {
      content: null,
      programLoader: true,
      // Initial page number for program if any
      coursePage: 1,
      coursesPerPage: 12,
      currentCoursePage: 1,
      totalCoursePages: 0,
      totalCourses: 0,

      programs: [],
      colval: "col-md-3",
      boards: [],
      boardId: "",
      standards: [],
      standardId: "",
      standardText: "",
      search: "",
      sortedTestimonials: [],
    };
  },
  created() {
    if (
      this.$route.query.standard !== undefined &&
      this.$route.query.standard.length !== 0
    ) {
      let standardToFilter = this.$route.query.standard;
      this.standardText = standardToFilter.replace("+", " ");
    } else {
      this.loadPrograms();
    }
    this.fetchContent();
    this.loadBoards();
    this.loadStandards();
    this.fetchTestimonials();
  },
  watch: {
    "$route.query.standard": function () {
      if (
        this.$route.query.standard != undefined &&
        this.$route.name == "ExploreCourses"
      ) {
        this.programLoader = true;
        let standardToFilter = this.$route.query.standard;
        this.standardText = standardToFilter.replace("+", " ");
        this.loadPrograms();
        this.fetchContent();
        this.loadBoards();
        this.loadStandards();
        this.fetchTestimonials();
      }
    },
  },

  methods: {
    resetProgramsFetch() {
      this.coursePage = 1;
      this.currentCoursePage = 1;
      this.loadPrograms();
    },
    async loadPrograms() {
      let params = {
        page: this.currentCoursePage,
        board_id: this.boardId,
        standard_id: this.standardId,
        search: this.search,
        per_page: this.coursesPerPage,
      };
      await CourseService.getPrograms(params)
        .then((response) => {
          this.programLoader = false;
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.programs = response.data.programs.data;
            // works only when totalCoursePages should be the last_page value
            this.totalCoursePages = response.data.programs.last_page;
            this.currentCoursePage = response.data.programs.current_page;
            this.totalCourses = response.data.programs.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          // console.log(this.programs);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    coursePaginateUpdateHandler(selectedPage) {
      if (this.currentCoursePage !== selectedPage) {
        this.currentCoursePage = selectedPage;
        this.scrollToElement(this.$refs.courses_head);
        this.loadPrograms();
      }
    },

    loadBoards() {
      CommonService.getActiveBoards()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.boards = response.data.boards;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadStandards() {
      CommonService.getActiveStandards()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.standards = response.data.standards;
            if (this.standardText !== "") {
              const standardFilter = this.standards.filter((standard) => {
                return standard.standard_title === this.standardText;
              });
              // console.log(standardFilter);
              if (standardFilter.length > 0) {
                this.standardId = standardFilter[0].id;
                this.loadPrograms();
              } else {
                this.programLoader = false;
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchContent() {
      let params = {
        slug: "explore",
      };
      CommonService.getContentPage(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.content = response.data.content;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchTestimonials() {
      CommonService.getTestimonials()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            let testimonials = response.data.testimonials;
            let count = testimonials.length;
            let j = 0;
            let k = 0;
            for (let i = 1; i <= count; i++) {
              j = k;
              k = k + 2;
              if (j >= count) {
                break;
              } else {
                this.sortedTestimonials.push(testimonials.slice(j, k));
              }
            }
            // console.log(this.sortedTestimonials);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    scrollToElement(refId) {
      const el = refId;
      if (el) {
        el.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/listing.scss";
</style>
